import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useEffect } from "react";
import ReactDOM from "react-dom";
export default function Paypal() {


    useEffect(() => {



        getByDisplayValue()



    }, [])
    const options = {
        "client-id": "YOUR_PAYPAL_CLIENT_ID",
        // Add any additional options here if required
      };
      
    const PayPalButtonWrapper = () => (
        <PayPalScriptProvider options={options}>
          <PayPalButtons onCreate={onCreate} />
        </PayPalScriptProvider>
      );

      const onCreate = (data, actions) => {
        // Custom code to handle the button creation
        // For example, you can modify the appearance, add event listeners, etc.
      };
    const getByDisplayValue = () => {

        var amount = 10.00

        return (
            <PayPalScriptProvider options={{ "client-id": "AVlwIceIbGLe1tr1kzHn_uQO10pO46E1Es9pJeps5EQrrXYpZklHg1PHZ5z1F995MkP-bZmT5GS13gCH" }}>
                <PayPalButtons onClick={() => alert("hiii")}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: amount,
                                    },
                                },
                            ],
                        });
                    }}
                    onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                            const name = details.payer.name.given_name;
                            alert(`Transaction completed by ${name}`);
                        });
                    }}
                />
            </PayPalScriptProvider>
        );

    }




    return (
        <div></div>
        // <PayPalScriptProvider options={{ "client-id": "AVlwIceIbGLe1tr1kzHn_uQO10pO46E1Es9pJeps5EQrrXYpZklHg1PHZ5z1F995MkP-bZmT5GS13gCH" }}>
        //     <PayPalButtons
        //         createOrder={(data, actions) => {
        //             return actions.order.create({
        //                 purchase_units: [
        //                     {
        //                         amount: {
        //                             value: "1.99",
        //                         },
        //                     },
        //                 ],
        //             });
        //         }}
        //         onApprove={(data, actions) => {
        //             return actions.order.capture().then((details) => {
        //                 const name = details.payer.name.given_name;
        //                 alert(`Transaction completed by ${name}`);
        //             });
        //         }}
        //     />
        // </PayPalScriptProvider>
    );
}

// ReactDOM.render(<PayPalButtonWrapper />, document.getElementById("root"));