import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './myorder.css'

import Sidebars from './sidebar';
import swal from 'sweetalert';


import Header from '../../layouts/Header/header';
import { getwishlist, wishlist } from '../../Api/homedata';
import { Circles, Vortex, RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { remove_wishlist_pro, updatestatus, wishlistSelector, wishlist_updatecart } from '../../Slices/wishlist';
import { Addtocart, fetchcart, updatemaincart } from '../../Slices/fetchcart';
import { IMAGE_URL } from '../../Constant';

import { BsCart2 } from 'react-icons/bs';

export default function Wishlist() {
  const dispatch = useDispatch()
  useEffect(() => {
    getwishlistpro()
    // setloading(true)
  }, []);


  const [loading, setloading] = useState(false)
  // const [wishlist, setWishlist] = useState([])

  const { wishlist_pro, loader } = useSelector(wishlistSelector)
  console.log("WISHLIST", wishlist_pro)
  const getwishlistpro = async () => {
    dispatch(updatestatus(true))
    const token = localStorage.getItem("user_token");
    // const output = await getwishlist(token)
    // setloading(false)
    // console.log("wishlist", output)
    // setWishlist(output.whishlist)
    if (token) {
      dispatch(fetchcart(token, 'initial'))
    }
  }

  const viewproduct = (e) => {
    // console.log("IDDDDDDDDDDDDDDD",e)
    // var data = JSON.stringify(e)
    //  localStorage.setItem("cart_product",e)
    window.location = "/viewproduct/" + e
  }

  if (wishlist_pro.length > 0) {
    dispatch(updatestatus(false))
  }

  // if (wishlist_pro.length == 0) {
  //   setloading(true)
  // }
  const toshow = async (index) => {
    const token = localStorage.getItem("user_token");
    dispatch(remove_wishlist_pro(index))
    var data = {
      id: wishlist_pro[index].id,
      token: token
    }
    const output = await wishlist(data)
    if (output) {
      swal({
        title: "Wishlist removed successfully!",
        icon: "warning",
        timer: 2000
      })
    }
  }

  const Addtocartfn = async (index) => {

    const token = localStorage.getItem("user_token");
    if (token) {

      var send = {
        key: 'increment',
        index: index
      }
      dispatch(wishlist_updatecart(send))
      var price = {

        productprice: Number(wishlist_pro[index].offerprice) > 0 ? Number(wishlist_pro[index].offerprice) * 1 : Number(wishlist_pro[index].price) * 1,

        cartlength: 1,
        type: 'increment',

      }
      dispatch(updatemaincart(price))
      const token = localStorage.getItem("user_token");
      var data = {
        token: token,
        productid: wishlist_pro[index].id,
        type: 'increment',
        process: "updatecart",
        varientid: wishlist_pro[index].varientid
      }
      dispatch(Addtocart(data))
    }
    else {
      swal("Please login to purchase a product.").then(function () {
        window.location = "/login";
      });

    }
  }


  const updatecart = (value, index, actualQty, currentQty) => {

    const token = localStorage.getItem("user_token");
    if (value == 'increment') {


      if (Number(currentQty) >= Number(actualQty)) {

        swal({
          title: "out of stock",
          icon: "warning",
          timer: 4000
        })


      }
      else {

        var send = {
          key: value,
          index: index
        }
        dispatch(wishlist_updatecart(send))

        var price = {

          productprice: Number(wishlist_pro[index].offerprice) > 0 ? Number(wishlist_pro[index].offerprice) * 1 : Number(wishlist_pro[index].price) * 1,


          cartlength: 0,
          type: 'increment'
        }
        dispatch(updatemaincart(price))

        var data = {
          token: token,
          productid: wishlist_pro[index].id,
          type: "increment",
          process: "updatecart",
          varientid: wishlist_pro[index].varientid,
        }
        dispatch(Addtocart(data))

      }
    }

    else {
      if (Number(wishlist_pro[index].qty == 1)) {
        var send = {
          key: value,
          index: index
        }
        dispatch(wishlist_updatecart(send))

        var price = {
          productprice: Number(wishlist_pro[index].offerprice) > 0 ? Number(wishlist_pro[index].offerprice) * 1 : Number(wishlist_pro[index].price) * 1,

          cartlength: Number(-1),
          type: 'decrement'
        }
        dispatch(updatemaincart(price))

        var data = {
          token: token,
          productid: wishlist_pro[index].id,
          type: "decrement",
          process: "updatecart",
          varientid: wishlist_pro[index].varientid,
        }
        dispatch(Addtocart(data))
      }
      else {
        var send = {
          key: value,
          index: index
        }
        dispatch(wishlist_updatecart(send))

        var price = {
          productprice: Number(wishlist_pro[index].offerprice) > 0 ? Number(wishlist_pro[index].offerprice) * 1 : Number(wishlist_pro[index].price) * 1,
          cartlength: 0,
          type: 'decrement'
        }
        dispatch(updatemaincart(price))

        var data = {
          token: token,
          productid: wishlist_pro[index].id,
          type: "decrement",
          process: "updatecart",
          varientid: wishlist_pro[index].varientid,
        }
        dispatch(Addtocart(data))
      }
    }

  }


  const viewdetail = (e) => {
    var data = JSON.stringify(e)
    localStorage.setItem("cart_product", data)
    window.location = "/viewproduct/" + e.id


  }





  return (

    <>

      <Header />

      <div className='allbody'>


        <div>


          <Container>

            <Row>

              <Col md={4}>

                <Sidebars />

              </Col>

              <Col md={8}>

                <Row>
                  {loader ?
                    <div className='firstsearch1'>
                      <RotatingLines
                        strokeColor="#0b316e"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                      />
                    </div>
                    :



                    wishlist_pro.length == 0 ?
                      <div className='firstsearch'>
                        <img style={{ height: "450px" }} src={require('../../assets/product-not-found.jpg')} class="img-fluid" alt="" />
                      </div>
                      :
                      wishlist_pro.length > 0 ?
                        <>
                          {wishlist_pro.map((e, i) =>

                            <div className='col-md-3 cardsection'>
                              <div class="card" style={{ height: "360px" }} >

                                <div className='cardiconflex'>


                                  <div>
                                    {e.discount > 0 ?
                                      <div>
                                        <label className='off-card'>{e.discount} % off</label>
                                      </div>
                                      : null}
                                  </div>

                                  <div>

                                    <div onClick={() => toshow(i)} style={{ color: "red", cursor: "pointer" }} className='hearticons'><i class="fa fa-heart"></i></div>
                                  </div>
                                </div>

                                <div onClick={() => viewdetail(e)} style={{ cursor: "pointer", textAlign: "center" }}>

                                  <img class="card-img-top category-img" src={URL = `${IMAGE_URL}` + e.image} alt="Card image cap" />

                                  <div class="card-body" style={{ textAlign: "center" }}>
                                    <h5 class="card-title">{e.name}</h5>
                                    {e.offerprice > 0 ?
                                      <span><span class="card-text"> $ {e.offerprice}</span> <span class="card-text1">$ {e.price}</span> </span>
                                      :
                                      <span class="card-text"> $ {e.price}</span>
                                    }
                                  </div>

                                </div>
                                {!e.check ?
                                  <>

                                    {e.status == "outOfStack" ?
                                      <div className='outofstack' >
                                        <p className='outofstack_text' >Out of Stock</p>
                                      </div>
                                      :
                                      <div class="padd10px" style={{ textAlign: "center" }}>
                                        <button onClick={() => Addtocartfn(i)} data-toggle="tooltip" title="Add to cart" data-button-action="add-to-cart"
                                          class="insp-cart-button">
                                          {/* <i class="fa fa-shopping-cart" aria-hidden="true"></i> */}
                                          <BsCart2 style={{ marginTop: "-3px" }} />  ADD
                                        </button>
                                      </div>
                                    }
                                  </>

                                  :
                                  <>
                                    <div class="padd10px" style={{ textAlign: "center" }}>
                                      <button class="cartbtn1st" onClick={() => updatecart("decrement", i, e.variant_quantity, e.qty)}>-</button>

                                      <span class="cartbtn2rd addcount" style={{ padding: "0px 8px" }}>{e.qty}</span>
                                      <button class="cartbtn3rd" onClick={() => updatecart("increment", i, e.variant_quantity, e.qty)}>+</button>
                                    </div>
                                  </>}


                              </div>
                            </div>

                          )}
                        </>

                        : null
                  }
                </Row>
              </Col>


            </Row>
          </Container>



        </div>

      </div>

    </>
  );

}